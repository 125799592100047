import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import axios from 'axios'
import Vue from 'vue'
import router from '../../../../router'

export default function useBranchesList() {
  const toast = useToast()
  const refBranchDetailsTable = ref(null)
  const refBranchDetailsTablePosition = ref(null)
  const tableColumns = [
    { key: 'no', sortable: false },
    { key: 'item_name', sortable: true },
    { key: 'item_code', sortable: true },
    { key: 'unit_name', sortable: false },
    { key: 'item_stock_shelf', sortable: false },
    { key: 'item_stock_current_available', sortable: false },
    { key: 'MinStock', sortable: false },
    { key: 'actions', sortable: false },
  ]
  const tableColumnsPosition = [
    { key: 'no', sortable: false },
    { key: 'item_code', sortable: true },
    { key: 'item_name', sortable: true },
    { key: 'item_category', sortable: true },
    { key: 'unit_name', sortable: false },
    { key: 'beginning', sortable: false },
    { key: 'in', sortable: false },
    { key: 'out', sortable: false },
    { key: 'available', sortable: false },
  ]
  const perPage = ref(10)
  const perPagePosition = ref(10)
  const totalItems = ref(0)
  const totalItemsPosition = ref(0)
  const currentPage = ref(1)
  const currentPagePosition = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const searchQueryPosition = ref('')
  const sortBy = ref('item_stock_id')
  const sortByPosition = ref('item_stock_id')
  const isSortDirDesc = ref(true)
  const isSortDirDescPosition = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refBranchDetailsTable.value ? refBranchDetailsTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })
  const dataMetaPosition = computed(() => {
    const localItemsCount = refBranchDetailsTablePosition.value ? refBranchDetailsTablePosition.value.localItems.length : 0
    return {
      from: perPagePosition.value * (currentPagePosition.value - 1) + (localItemsCount ? 1 : 0),
      to: perPagePosition.value * (currentPagePosition.value - 1) + localItemsCount,
      of: totalItemsPosition.value,
    }
  })

  const refetchData = () => {
    refBranchDetailsTable.value.refresh()
    refBranchDetailsTablePosition.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, sortBy, isSortDirDescPosition,currentPagePosition, perPagePosition, searchQueryPosition, sortByPosition, isSortDirDescPosition], () => {
    refetchData()
  })
  const fetchItems = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const dataDetails = Vue.$cookies.get('BranchDetail')
    const branchId = dataDetails.branch_id
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    const to = perPage.value * (currentPage.value - 1)
    const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ITEM_STOCKS}?branchId=${branchId}&limit=${perPage.value}&offset=${to}&search=${searchQuery.value}&order_by=${sortBy.value}&order_sort=${isSortDirDesc.value ? 'DESC' : 'ASC'}`
    axios
      .get(url, { headers })
      .then(response => {
        if (response.data.data !== null) {
          totalItems.value = response.data.data_count
        callback(response.data.data)
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error Fetching Items list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchItemsPosition = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const dataDetails = Vue.$cookies.get('BranchDetail')
    const branchId = dataDetails.branch_id
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    const to = perPagePosition.value * (currentPagePosition.value - 1)
    const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ITEM_STOCKS_POSITION}?branchId=${branchId}&limit=${perPagePosition.value}&offset=${to}&search=${searchQueryPosition.value}&order_by=${sortByPosition.value}&order_sort=${isSortDirDescPosition.value ? 'DESC' : 'ASC'}`
    axios
      .get(url, { headers })
      .then(response => {
        if (response.data.data !== null) {
          totalItemsPosition.value = response.data.data_count
        callback(response.data.data)
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error Fetching Items list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'super-admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'super-admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchItems,
    fetchItemsPosition,
    tableColumns,
    tableColumnsPosition,
    perPage,
    perPagePosition,
    currentPage,
    currentPagePosition,
    totalItems,
    totalItemsPosition,
    dataMeta,
    dataMetaPosition,
    perPageOptions,
    searchQuery,
    searchQueryPosition,
    sortBy,
    sortByPosition,
    isSortDirDesc,
    isSortDirDescPosition,
    refBranchDetailsTable,
    refBranchDetailsTablePosition,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
  }
}

<template>

  <div>

    <!-- Modal Min Stock-->
    <b-modal
      id="modal-minstick"
      v-model="modalMinstock"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Minimal Stock"
    >
      <b-form>
        <div>
          <div class="messages">
            <form id="send_message_form" method="post" action="">
              <b-row>
                <b-col cols="11" md="9">
                  <input
                    v-model="ItemMinStock"
                    type="number"
                    min="1"
                    class="form-control"
                    placeholder="Put Minimal Stock"
                  />
                </b-col>
                <b-col cols="11" md="3"
                  ><input
                    type="button"
                    class="btn btn-primary pull-right"
                    value="Submit"
                    @click="submitMinStock()"
                /></b-col>
              </b-row>
            </form>
          </div>
        </div>
      </b-form>
    </b-modal>
    <item-add-new
      :is-add-new-item-sidebar-active.sync="isAddNewItemSidebarActive"
      :role-options="roleOptions"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
    <b-tabs>
        <b-tab title="Stocks">
      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            cols="6"
          >
            <h4>Stock of {{branchName}}</h4>
            <br>
            <b-button
              type="button"
              variant="primary"
              class="mr-1"
              @click="previous()"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="align-middle"
              />
              Back
            </b-button>
            <br>
            <br>
          </b-col>
          <b-col cols="6">
            <div class="d-flex justify-content-end align-items-end">
              <b-button variant="primary" @click="exportItemStock()" :disabled="isLoadingExport">
                <feather-icon icon="DownloadIcon" /> Export
                <b-spinner small type="grow" v-if="isLoadingExport"></b-spinner>
              </b-button>
            </div>
          </b-col>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                v-if="createShow"
                variant="primary"
                @click="addNew()"
              >
                <span class="text-nowrap">Add Item Stock Initialization</span>
              </b-button>
            </div>
          </b-col>
          <!-- Search -->
        </b-row>

      </div>

      <b-table
        ref="refBranchDetailsTable"
        class="position-relative"
        :items="fetchItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(no)="data">
        <div class="text-nowrap">
          <span class="align-text-top">{{ (currentPage - 1) * perPage + data.index + 1 }}</span>
        </div>
      </template>
        <!-- Column: ItemName -->
        <template #cell(item_name)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.item.item_name }}</span>
          </div>
        </template>
        <template #cell(item_code)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.item.item_code }}</span>
          </div>
        </template>
        <template #cell(unit_name)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.item.unit!=null?data.item.item.unit.unit_name:'-'}}</span>
          </div>
        </template>
        <!-- Column: Shelf -->
        <template #cell(shelf)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.item_stock_shelf }}</span>
          </div>
        </template>
        <!-- Column: CurrentStock -->
        <template #cell(item_stock_current_available)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.item_stock_current_available }}</span>
          </div>
        </template>
        <!-- Column: MinStock -->
        <template #cell(MinStock)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.item_min_stock }}</span>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="showStockHistory(data.item.item, data.item.item_stock_shelf)"
            >
              <feather-icon icon="RefreshCcwIcon" />
              <span class="align-middle ml-50">Stock History</span>
            </b-dropdown-item>
            <b-dropdown-item @click="setMinStock(data.item.item.item_id)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Set Min Stock</span>
            </b-dropdown-item>
            </b-dropdown>
            </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="dataMeta.of"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      </b-tab>
        <b-tab title="Stock Position">
      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            cols="6"
          >
            <h4>Stock Position of {{branchName}}</h4>
            <br>
            <b-button
              type="button"
              variant="primary"
              class="mr-1"
              @click="previous()"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="align-middle"
              />
              Back
            </b-button>
            <br>
            <br>
          </b-col>
          <b-col cols="6">
            <div class="d-flex justify-content-end align-items-end">
              <b-button variant="primary" @click="exportItemStockPosition()" :disabled="isLoadingExportPosition">
                <feather-icon icon="DownloadIcon" /> Export
                <b-spinner small type="grow" v-if="isLoadingExportPosition"></b-spinner>
              </b-button>
            </div>
          </b-col>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPagePosition"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQueryPosition"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
          <!-- Search -->
        </b-row>

      </div>

      <b-table
        ref="refBranchDetailsTablePosition"
        class="position-relative"
        :items="fetchItemsPosition"
        responsive
        :fields="tableColumnsPosition"
        primary-key="id"
        :sort-by.sync="sortByPosition"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDescPosition"
      >
      <template #cell(no)="data">
        <div class="text-nowrap">
          <span class="align-text-top">{{ (currentPagePosition - 1) * perPagePosition + data.index + 1 }}</span>
        </div>
      </template>
        <!-- Column: ItemCode -->
      <template #cell(item_code)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.item.item_code }}</span>
        </div>
      </template>
      <!-- Column: ItemName -->
        <template #cell(item_name)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.item.item_name }}</span>
          </div>
        </template>
        <!-- Column: ItemCategory -->
      <template #cell(item_category)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.item.item_category }}</span>
        </div>
      </template>
        <template #cell(unit_name)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.item.unit!=null?data.item.item.unit.unit_name:'-'}}</span>
          </div>
        </template>
        <!-- Column: Beginning -->
        <template #cell(beginning)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.beginning }}</span>
          </div>
        </template>
        <!-- Column: In -->
        <template #cell(in)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.in }}</span>
          </div>
        </template>
        <!-- Column: Out -->
        <template #cell(out)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.out }}</span>
          </div>
        </template>
        <!-- Column: Available -->
        <template #cell(available)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.available }}</span>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMetaPosition.from }} to {{ dataMetaPosition.to }} of {{ dataMetaPosition.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPagePosition"
              :total-rows="dataMetaPosition.of"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTab, BTabs,
} from 'bootstrap-vue'
import axios from 'axios'
import vSelect from 'vue-select'
import store from '@/store'
import moment from "moment"
import { saveAs } from 'file-saver'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import BranchDetails from './BranchDetails'
import itemModule from '../itemModule'
import ItemAddNew from './ItemAddNew.vue'

const userRole = ''
const branchName = ''
const branchId = 0
const modalMinstock = false
const ItemMinStock = ''

export default {
  components: {
    // ItemesListFilters,
    ItemAddNew,
    BTab,
    BTabs,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  mounted() {
    const dataDetails = this.$cookies.get('BranchDetail')
    this.branchName = `${dataDetails.branch_name} (${dataDetails.branch_code})`
    this.branchId = dataDetails.branch_id
    console.log("branch id", this.branchId)
    this.userRole = this.$cookies.get('UserRole')
    console.log("USER ROLE", this.userRole)
    if (this.excludeRoleToCreate.includes(this.userRole)) {
      this.createShow = false
    }
    this.$root.$on('refreshTable', text => {
    console.log(text) // here you need to use the arrow function
    this.$refs.refBranchDetailsTable.refresh()
  })
  },
  setup() {
    const BRANCH_APP_STORE_MODULE_NAME = 'app-item'

    // Register module
    if (!store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) store.registerModule(BRANCH_APP_STORE_MODULE_NAME, itemModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) store.unregisterModule(BRANCH_APP_STORE_MODULE_NAME)
    })

    const isAddNewItemSidebarActive = ref(false)

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchItems,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBranchDetailsTable,
      fetchItemsPosition,
      tableColumnsPosition,
      perPagePosition,
      currentPagePosition,
      totalItemsPosition,
      dataMetaPosition,
      searchQueryPosition,
      sortByPosition,
      isSortDirDescPosition,
      refBranchDetailsTablePosition,
      refetchData,

      // UI
      resolveItemRoleVariant,
      resolveItemRoleIcon,
      resolveItemStatusVariant,

      // Extra Filters
      roleFilter,
      statusFilter,
    } = BranchDetails()

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    return {
      ItemMinStock,
      modalMinstock,
      branchName,
      branchId,
      // Sidebar
      isAddNewItemSidebarActive,
      userRole,
      fetchItems,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBranchDetailsTable,
      refetchData,

      fetchItemsPosition,
      tableColumnsPosition,
      perPagePosition,
      currentPagePosition,
      totalItemsPosition,
      dataMetaPosition,
      searchQueryPosition,
      sortByPosition,
      isSortDirDescPosition,
      refBranchDetailsTablePosition,
      // Filter
      avatarText,

      // UI
      resolveItemRoleVariant,
      resolveItemRoleIcon,
      resolveItemStatusVariant,

      roleOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      statusFilter,
      createShow: true,
      excludeRoleToCreate: ['laboratory-manager'],
      isLoadingExport: false,
      isLoadingExportPosition: false,
    }
  },
  methods: {
    exportItemStock() {
      const userToken = this.$cookies.get("userToken")
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      }
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_EXPORT_ITEM_STOCKS}?branch_id=${this.branchId}`
      this.isLoadingExport = true

      axios.get(url, {
          headers,
          responseType: 'arraybuffer',
      }).then(response => {
        console.log(response.data)
        console.log(response.headers)

        const filename = "report_item_stock.xlsx"
        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: response.headers['content-type'] })

        // Trigger the download using FileSaver.js
        saveAs(blob, filename)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Export Item Stock Success",
            icon: "AlertTriangleIcon",
            variant: "success",
          },
        })
      }).finally(() => {
        this.isLoadingExport = false
      })
    },
    exportItemStockPosition() {
      const userToken = this.$cookies.get("userToken")
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      }
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_EXPORT_ITEM_STOCKS_POSITION}?branch_id=${this.branchId}`
      this.isLoadingExportPosition = true

      axios.get(url, {
          headers,
          responseType: 'arraybuffer',
      }).then(response => {
        console.log(response.data)
        console.log(response.headers)

        const filename = "report_item_stock.xlsx"
        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: response.headers['content-type'] })

        // Trigger the download using FileSaver.js
        saveAs(blob, filename)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Export Item Stock Success",
            icon: "AlertTriangleIcon",
            variant: "success",
          },
        })
      }).finally(() => {
        this.isLoadingExport = false
      })
    },
      deleteItem(id) {
        const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
        const body = {
          ItemID: id.toString(),
        }
          axios
              .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DELETE_BRANCH}`, body, { headers })
              .then(response => {
                console.log(response)
                if (response.data.Status === 1) {
                  this.$refs.refBranchDetailsTable.refresh()
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Congratulation',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Your data has been deleted!',
                    },
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Notification',
                      icon: 'BellIcon',
                      variant: 'danger',
                      text: 'Something went wrong',
                    },
                  })
                }
              })
              .catch(err => {
                  console.log(err)
              })
      },
      editItem(data) {
          localStorage.setItem("itemEdit", JSON.stringify(data))
          this.$router.push({ name: 'apps-item-edit' })
      },
        showStockHistory(data, shelf) {
          localStorage.setItem("ShelfDetails", shelf)
          localStorage.setItem("ItemDetails", JSON.stringify(data))
          this.$router.push({ name: "apps-items-history-lists" })
        },
      numberFormat(value) {
        return parseFloat(value).toFixed(2)
      },
    addNew() {
          this.$router.push({ name: 'apps-item-stocks-create' })
    },
    previous() {
          this.$router.push({ name: 'apps-item-stocks-list' })
    },
    setMinStock(id) {
      this.ItemId = id
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_MIN_STOCK_ITEM}${id}`
      const userToken = this.$cookies.get("userToken")
      const header = {
        "Content-Type": "multipart/form-dataitem",
        Authorization: `Bearer ${userToken}`,
      }
      axios.get(url, { headers: header }).then(response => {
        if (response.data.success === true) {
          this.ItemMinStock = response.data.data.item_min_stock_number
          this.modalMinstock = true
        } else {
          this.ItemMinStock = 0
          this.modalMinstock = true
        }
      })
    },
    submitMinStock() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_UPDATE_MIN_STOCK_ITEM}${this.ItemId}`
      const formData = {
        item_min_stock_number: this.ItemMinStock,
        time: moment(),
      }
      axios
        .post(url, formData, { headers, crossDomain: true })
        .then(response => {
          if (response.data.success === true) {
            this.modalMinstock = false
            this.$refs.refItemListTable.refresh()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Update Success",
                icon: "CoffeeIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
